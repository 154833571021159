import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { Controller } from 'react-hook-form';
import {
  TextField,
  CircularProgress,
  Divider,
  FormGroup,
  FormControlLabel,
  InputLabel,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Typography,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import Delete from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { WarningMessage } from './WarningMessage';

import { ChangeOrderButton } from './ChangeOrderButton';
import { ARR, OBJ, getUrnoidToPropFunc } from '../utils/data-utils';

const emailUrnoid = 'urn:oid:0.9.2342.19200300.100.1.3';
const personalNumberUrnoid = 'urn:oid:1.2.752.29.4.13';

export const Signer = ({
  name,
  existingEmail,
  existingSignerName = '',
  existingSignerPersonalID = '',
  index = 0,
  classes,
  move,
  remove,
  register,
  totalCount,
  errors = OBJ,
  idps = ARR,
  disabledCheckbox = false,
  setValue,
  getValues,
  repeatedEmailErrors,
  signed = false,
  disableArrows = { up: false, down: false },
  personalNumberCheckbox,
  control,
  idpsForSigner,
}) => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const isEmailError = R.has('email', errors);
  const isPersonalNumberError = R.has('personalID', errors);
  const isCheckboxError = R.has('idp', errors);
  const emailName = `${name}.email`;
  const nameName = `${name}.name`;
  const { features } = theme;
  const personalNumberOption =
    features && 'personalNumberInCases' in features ? features.personalNumberInCases : null;

  const [requirePersonalNumber, setRequirePersonalNumber] = useState(!!existingSignerPersonalID);

  const signerNameFeature =
    features && 'useSignerName' in features ? features.useSignerName : false;

  const [signer, setSigner] = useState(null);

  const updateSigner = () => {
    const values = getValues();
    if (values && values.signers && values.signers.length > index)
      setSigner({ ...values.signers[index] });
  };

  const setIdpValue = (id, val) => {
    setValue(`${name}.idpsForSigner.${id}`, val);
    updateSigner();
  };

  const setEmailValue = (val) => {
    setValue(`${name}.email`, val);
    updateSigner();
  };

  const setSignerNameValue = (val) => {
    setValue(`${name}.name`, val);
    updateSigner();
  };

  const setPersonalNumberCheckboxValue = (val) => {
    setValue(`${name}.personalNumberCheckbox`, val);
    updateSigner();
  };

  useEffect(() => {
    setPersonalNumberCheckboxValue(personalNumberCheckbox);
  }, [personalNumberCheckbox]);

  useEffect(() => {
    setEmailValue(existingEmail);
  }, [existingEmail]);

  useEffect(() => {
    setSignerNameValue(existingSignerName);
  }, [existingSignerName]);

  useEffect(() => {
    if (idps.length) {
      idps.forEach((idp) => {
        if (!idp.deselected) {
          setIdpValue(idp.id, true);
        }
      });
    }
    updateSigner();
  }, [idps]);

  useEffect(() => {
    setRequirePersonalNumber(!!existingSignerPersonalID);
    setValue(`${name}.personalID`, existingSignerPersonalID);
    updateSigner();
  }, [existingSignerPersonalID]);

  useEffect(() => {
    setRequirePersonalNumber(personalNumberCheckbox);
    setPersonalNumberCheckboxValue(personalNumberCheckbox);
    updateSigner();
  }, [personalNumberCheckbox]);
  // update form if idpscheckBox states changes
  useEffect(() => {
    if (!idpsForSigner || Object.keys(idpsForSigner).length < 1) return;
    Object.keys(idpsForSigner).forEach((idpName) => setIdpValue(idpName, !!idpsForSigner[idpName]));
    updateSigner();
  }, [idpsForSigner]);

  const requirePersonalNumberChange = (event, val) => {
    setRequirePersonalNumber(val);
    setValue(`${name}.personalNumberCheckbox`, val);
    if (val) {
      idps.forEach((idp) => {
        if (!idp.containsPersonalNumber) {
          setValue(`${name}.idpsForSigner.${idp.id}`, false);
        }
      });
    }
    updateSigner();
  };

  const { label: emailLabel, validation: emailValidation } = getUrnoidToPropFunc(emailUrnoid)(t);
  const { label: personalNumberLabel, validation: personalNumberValidate } = getUrnoidToPropFunc(
    personalNumberUrnoid,
  )(t);

  const languagePrefix = i18n.language.split('-')[0];
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <Card className={classes.box}>
      <CardContent>
        <Typography variant="subtitle1" className={classes.uppercase}>
          {t('Signer details', { index: totalCount < 2 ? undefined : index + 1 })}
        </Typography>
        <Divider />
        <InputLabel htmlFor="email" className={classes.subtitle} required>
          {emailLabel}
        </InputLabel>
        <TextField
          disabled={signed}
          name={emailName}
          id="email"
          inputRef={register({ ...emailValidation })}
          fullWidth
          defaultValue={signer?.email}
          helperText={isEmailError ? '' : t('Please make sure you type the correct address')}
          autoFocus
          error={isEmailError || repeatedEmailErrors}
          onInput={(e) => {
            setEmailValue(e.target.value);
          }}
          onChange={(e) => {
            setEmailValue(e.target.value);
          }}
          inputProps={{
            maxLength: 254, // RFC 5321 standard
          }}
          onKeyDown={(e) => checkKeyDown(e)}
        />
        {isEmailError && <Typography color="error">{errors.email.message}</Typography>}
        {repeatedEmailErrors && <Typography color="error">{repeatedEmailErrors}</Typography>}
        {signerNameFeature && (
          <>
            <InputLabel htmlFor="name" className={classes.subtitle}>
              {t('Name (firstname lastname) (optional)')}
            </InputLabel>
            <TextField
              disabled={signed}
              name={nameName}
              id="name"
              inputRef={register()}
              fullWidth
              defaultValue={signer?.name}
              helperText=""
              autoFocus
              inputProps={{
                maxLength: 254, // RFC 5321 standard
              }}
              onKeyDown={(e) => checkKeyDown(e)}
            />
          </>
        )}
        {personalNumberOption && (
          <div className={{ ...classes.box, ...classes.personalIdCheckbox }}>
            <FormControlLabel
              control={
                <Controller
                  name={`${name}.personalNumberCheckbox`}
                  control={control}
                  defaultValue={signer?.personalNumberCheckbox}
                  render={({ value, ref, onChange }) => (
                    <Checkbox
                      size="medium"
                      inputRef={ref}
                      checked={value}
                      disabled={disabledCheckbox}
                      onChange={(e, val) => {
                        requirePersonalNumberChange(e, val);
                        onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              }
              label={t('Require personal number')}
            />
          </div>
        )}
        {requirePersonalNumber && (
          <>
            <InputLabel htmlFor="personalID" className={classes.subtitle} required>
              {personalNumberLabel}
            </InputLabel>
            <TextField
              disabled={signed}
              name={`${name}.personalID`}
              defaultValue={signer?.personalID}
              id="personalID"
              inputRef={register({ ...personalNumberValidate })}
              fullWidth
              autoFocus
              error={isPersonalNumberError}
              onKeyDown={(e) => checkKeyDown(e)}
            />
            {isPersonalNumberError && (
              <Typography color="error">{errors.personalID.message}</Typography>
            )}
          </>
        )}

        <FormGroup row id="method">
          {!R.isEmpty(idps) ? (
            idps.map((idp) => (
              <FormControlLabel
                key={idp.id}
                control={
                  <Controller
                    name={`${name}.idpsForSigner.${idp.id}`}
                    control={control}
                    defaultValue={!!signer?.[idp.id]}
                    render={({ value, ref, onChange }) => (
                      <Checkbox
                        size="medium"
                        inputRef={ref}
                        checked={value}
                        disabled={
                          (requirePersonalNumber && !idp.containsPersonalNumber) || disabledCheckbox
                        }
                        onChange={(e, val) => {
                          setIdpValue(idp.id, val);
                          onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                }
                label={
                  idp[`displayName_${languagePrefix}`]
                    ? idp[`displayName_${languagePrefix}`]
                    : idp.displayName
                }
                disabled={
                  (requirePersonalNumber && !idp.containsPersonalNumber) || disabledCheckbox
                }
              />
            ))
          ) : (
            <CircularProgress />
          )}
        </FormGroup>
        {signer && !!signer.SMS && !signed && (
          <WarningMessage message="SMS is a weaker authentication method" />
        )}
        {isCheckboxError && <Typography color="error">{errors.idp.message}</Typography>}
      </CardContent>
      <CardActions>
        {index !== totalCount - 1 && !signed && !disableArrows.down && (
          <ChangeOrderButton onClick={() => move(index, index + 1)} ariaLabel={t('Move down')} />
        )}
        {index !== 0 && !disableArrows.up && (
          <ChangeOrderButton
            direction="up"
            onClick={() => move(index, index - 1)}
            ariaLabel={t('Move Up')}
          />
        )}
        {totalCount !== 1 && !signed && (
          <Tooltip title={t('Delete')}>
            <IconButton onClick={() => remove(index)} aria-label={t('Delete')}>
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};
