import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import { Layout } from './Layout';
import { ErrorPage } from './exceptionHandler/ErrorPage';
import { SettingsPage } from './settings/SettingsPage';
import { ChangeCasePage } from './ChangeCasePage';
import { isSender } from '../utils/jwt';
// import { NotFoundPage } from './NotFoundPage';

const SignPage = lazy(() => import('./LazyLoading/LazySignPage'));
const CasesPage = lazy(() => import('./LazyLoading/LazyCasesPage'));
const CaseViewPage = lazy(() => import('./LazyLoading/LazyCaseViewPage'));
const Create = lazy(() => import('./LazyLoading/LazyCreate'));
const QuickSign = lazy(() => import('./LazyLoading/LazyQuickSign'));
const ValidatorOverviewPage = lazy(() => import('./LazyLoading/LazyValidatorOverviewPage'));

export const AppRouter = () => {
  const { features } = useTheme();
  const quickSign = isSender() && features && 'quickSign' in features ? features.quickSign : null;
  return (
    <Layout>
      <Switch>
        <Route path="/exception/:errorMsg?/:errorCode?" exact>
          <Suspense fallback={<CircularProgress />}>
            <ErrorPage />
          </Suspense>
        </Route>
        <Route path="/cases/:id/:message?">
          <Suspense fallback={<CircularProgress />}>
            <CaseViewPage />
          </Suspense>
        </Route>
        {isSender() && (
          <Route path="/change/:id/" exact>
            <Suspense fallback={<CircularProgress />}>
              <ChangeCasePage />
            </Suspense>
          </Route>
        )}
        {isSender() && (
          <Route path="/create" exact>
            <Suspense fallback={<CircularProgress />}>
              <Create />
            </Suspense>
          </Route>
        )}
        <Route path="/sign/:id" exact>
          <Suspense fallback={<CircularProgress />}>
            <SignPage />
          </Suspense>
        </Route>

        {quickSign && (
          <Route path="/quicksign" exact>
            <Suspense fallback={<CircularProgress />}>
              <QuickSign />
            </Suspense>
          </Route>
        )}
        <Route path="/validate" exact>
          <Suspense fallback={<CircularProgress />}>
            <ValidatorOverviewPage />
          </Suspense>
        </Route>
        <Route path={['/settings/:page', '/settings']} exact>
          <Suspense fallback={<CircularProgress />}>
            <SettingsPage />
          </Suspense>
        </Route>
        <Route path="/">
          <Suspense fallback={<CircularProgress />}>
            <CasesPage />
          </Suspense>
        </Route>
        {
          //   <Route>
          //   <NotFoundPage />
          // </Route>
        }
      </Switch>
    </Layout>
  );
};
