import React, { useContext } from 'react';
import { useLocation, Link, matchPath } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Tooltip, Hidden } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { drawerWidth, menuItems, pageTitles, drawerBreakpoint } from '../app.config';
import { DrawerContext } from '../store/appContext';
import { LogoutButton } from './LogoutButton';
import { shouldBeVisible } from './AppDrawer';

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up(drawerBreakpoint)]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.palette.headerColor,
    color: theme.palette.headerTextColor,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(drawerBreakpoint)]: {
      display: 'none',
    },
  },
  title: { flexGrow: 1 },
}));

const getTitle = (t, location, features) => {
  const title = [...menuItems, ...pageTitles].find((item) =>
    matchPath(location?.pathname, { path: item.to, exact: true }),
  )?.primary;
  if (title === 'Create request') {
    return 'Create sign request';
  }
  if (title && shouldBeVisible(title, features)) {
    return title;
  }
  const primaryUrl = location?.pathname.split('/');
  if (primaryUrl && primaryUrl.length > 1) {
    if (primaryUrl[1] === 'exception') {
      // Don't show title at error page
      return '';
    }
  }
  // Router will default to view-cases page
  return 'Sign requests';
};

export const MainAppBar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setDrawer } = useContext(DrawerContext);
  const { features } = useTheme();

  const location = useLocation();
  const title = getTitle(t, location, features);

  const renderLink = React.useMemo(() =>
    React.forwardRef((itemProps, ref) => <Link to="/settings" ref={ref} {...itemProps} />),
  );

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Tooltip title={t('Side menu')}>
          <IconButton
            color="inherit"
            aria-label={t('Side menu')}
            edge="start"
            onClick={() => void setDrawer((x) => !x)}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h6" noWrap className={classes.title}>
          {t(title)}
        </Typography>
        <Hidden xsDown>
          <IconButton
            component={renderLink}
            aria-label={t('Profile')}
            aria-controls="appbar-menu-profile"
            aria-haspopup="true"
            color="inherit"
            href="/settings"
            style={{ marginLeft: '1em' }}
          >
            <AccountCircle />
          </IconButton>
          <LogoutButton />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
