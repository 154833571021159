import React, { useState } from 'react';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  ListItemText,
  Typography,
  Tooltip,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import { useTranslation } from 'react-i18next';

import { ARR, isPDFFile, isFilenameAllowed } from '../utils/data-utils';
import { Dropzone } from './Dropzone';

const useStyles = makeStyles(() => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  ellipsisText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export const FileSelector = ({
  setValue,
  files = ARR,
  register,
  error,
  className,
  isChangeCaseComponent = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filesRejected, setFilesRejected] = useState('');
  const [corruptFiles, setCorruptFiles] = useState([]);

  const clearFileStates = () => {
    setFilesRejected('');
    setCorruptFiles([]);
  };

  return (
    <FormControl error fullWidth className={className}>
      <Typography variant="subtitle1" className={classes.uppercase}>
        {isChangeCaseComponent ? t('Documents') : t('Upload documents')}
      </Typography>
      <Divider />
      {!isChangeCaseComponent && (
        <Dropzone
          disabled={isChangeCaseComponent}
          render={async ({ acceptedFiles, namesRejectedFiles }) => {
            /*
           Validated inside the render function as validator in react-dropzone
           does not accept async validation-functions
          */
            const validatedFiles = [];
            const newCorruptFiles = [];
            const rejectedFiles = [];
            const dropZoneRejectedFileNames = await namesRejectedFiles;
            if (dropZoneRejectedFileNames) {
              rejectedFiles.push(dropZoneRejectedFileNames);
            }
            if (acceptedFiles && acceptedFiles.length) {
              await Promise.all(
                acceptedFiles.map(async (file) => {
                  if (!isFilenameAllowed(file)) {
                    rejectedFiles.push(file.name);
                  } else if (!(await isPDFFile(file))) {
                    newCorruptFiles.push(file.name);
                  } else {
                    validatedFiles.push(file);
                  }
                }),
              );
            }
            setFilesRejected(rejectedFiles.join(', '));
            setCorruptFiles(newCorruptFiles);
            setValue('files', [...files, ...validatedFiles], { shouldValidate: true });
          }}
          name="files"
          register={register}
        />
      )}
      {error && (
        <Typography color="error" style={{ marginTop: '0.5em' }}>
          {error.message}
        </Typography>
      )}
      <List dense>
        {files.map(({ name = '' }, index) => (
          <ListItem key={name}>
            <ListItemAvatar>
              <Avatar>
                <FileIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={name}
              classes={{
                primary: classes.ellipsisText,
              }}
            />
            {!isChangeCaseComponent && (
              <ListItemSecondaryAction>
                <Tooltip title={t('Delete')}>
                  <IconButton
                    edge="end"
                    aria-label={t('Delete')}
                    onClick={() => {
                      setValue('files', R.remove(index, 1)(files));
                      clearFileStates();
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
      {filesRejected.length ? (
        <Typography color="error" paragraph>
          {t('Not accepted', { str: filesRejected })}
        </Typography>
      ) : null}
      {corruptFiles.length ? (
        <>
          {corruptFiles.map((file) => (
            <Typography color="error" key={file} paragraph>
              {t('The file ')}
              <b>{file}</b>
              {t(
                ' is not a valid PDF file. Please make sure the file is not damaged or corrupted and try again.',
              )}
            </Typography>
          ))}
        </>
      ) : null}
    </FormControl>
  );
};
